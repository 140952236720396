import '../css/App.css'
import '../css/Navbar.css'
import {} from 'react-router-dom'
import LinkButton from './LinkButton';
import MenuIcon from '@mui/icons-material/Menu';


import { useEffect, useRef, useState } from 'react';

import { Link, useResolvedPath, useLocation} from 'react-router-dom';
import NavbarLogo from './NavbarLogo';

export default function Navbar() {

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  let [isMobile, setIsMobile] = useState(false);

  const navMenu = useRef(null);
  const navLogo = useRef(null);
  const nav = useRef(null);
  const navbarBlur = useRef(null);


    function NavLink ({ to, children}){
      let location = useLocation();
      let path = useResolvedPath(to);
      let isActive = null;

      

      //if location is the same as the path of this nav item (and it's not home lol)
      if(location.pathname.startsWith(path.pathname) && path.pathname !== "/"){
        //user is on this page
        isActive = true;
      }
      //if location is the same as the path of this nav item (and it IS home )
      else if(location.pathname.startsWith(path.pathname) && location.pathname === "/"){
        isActive = true;
      }
      else{
        isActive = false;
      }


      return (
        <div className={isActive ? "activeLink navItem" : 'navItem' }>
            <Link to={to}>
              <h3 className= {isMobile ? "no-underline" :'hover-underline-animation'}>{children}</h3>
            </Link>
        </div>
      )
    }


    function handleOpenMenu(){
      
      var scrollPos = window.scrollY;
      
      //this means true for some reason idk why
      if(isNavExpanded === false){
        document.body.style.position = 'fixed';
        document.body.style.top = "-"+scrollPos+'px';
        navLogo.current.style.visibility = "hidden";
      }
      //if navbar isn't open
      else{
        const scrollToPos = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollToPos || '0') * -1);
        navLogo.current.style.visibility = "visible";
      }
      
      setIsNavExpanded(!isNavExpanded);

    }

    useEffect(() => {
      let scrollPos = 0;

      //check if it's opened on mobile
      if(window.innerWidth <= 1080){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }

      // Bind the event listeners
      window.addEventListener('scroll', showNav, {passive: true});


      //when scrolled up, show navigation
      function showNav() {
        const temp = window.scrollY;
    
        if(temp < scrollPos){
          /* show nav */
          nav.current.classList.remove("navbarHide");
    
        }
        else if(temp > scrollPos){
          /* hide nav */
          if(scrollPos > 50){
            nav.current.classList.add("navbarHide");
          }
          
        }

        scrollPos = window.scrollY;

        //add blur when scrolled down further
        if(temp <= 0){
            navbarBlur.current.style.opacity = "0";
        }
        else{
          navbarBlur.current.style.opacity = "1";
        }
    
      };

    
      }, []
      )

    return (
      <>
        <div className='navbarContainer' ref={nav}>
            <div className='navbarBlur' ref={navbarBlur}></div>
            <div className='navbar'>
                <div className='navLogoContainer' ref={navLogo} >
                  <NavbarLogo />
                  
                </div>
                <div className='navItemContainer '>
                    
                    <NavLink to="/">Home</NavLink>

                    <NavLink to="/news">News</NavLink>

                    <NavLink to="/ourteam">Our Team</NavLink>

                    <NavLink to="/contact">Contact</NavLink>

                    <div className='navItem'>
                    <LinkButton 
                        label="Join Our Club"
                        path="https://docs.google.com/forms/d/e/1FAIpQLSeoS7i4UlSE0Fz1uUTX-2gnheiXxwpn3SsX_WZKcRj8GuU1_Q/viewform?fbclid=IwAR2F-41lvN28vTdHcibGZYWenNw844gEUmQ5QOWnv2qhPZ-cNSGva6At5UA"
                        className="navButton" />
                    </div>

                    <div className='navHamburger' onClick={() => handleOpenMenu()}>
                      <MenuIcon className='menuIcon' />
                    </div>
                </div>

            </div>
        </div>
        <div className={isNavExpanded ? "mobile-nav-menu open" : "mobile-nav-menu closed"}>
          <div className={isNavExpanded ? "navMenuCloseArea" : "navMenuCloseArea hidden"} onClick={() => handleOpenMenu()}>
          </div>
          <div ref={navMenu} className={isNavExpanded ? "expanded" : "not expanded"}>
                    <Link to="/" id="nav-menu-item" onClick={() => handleOpenMenu()}>
                      <NavLink to="/" >Home</NavLink>
                    </Link>
                    
                    <Link to="/news" id="nav-menu-item" onClick={() => handleOpenMenu()}>
                      <NavLink to="/news">News</NavLink>
                    </Link>
                    
                    <Link to="/ourteam" id="nav-menu-item" onClick={() => handleOpenMenu()}>
                      <NavLink to="/ourteam">Our Team</NavLink>
                    </Link>
                    
                    <Link to="/contact" id="nav-menu-item" onClick={() => handleOpenMenu()}>
                      <NavLink to="/contact">Contact</NavLink>
                    </Link>
                    
                    <div className='navItem'>
                    <LinkButton 
                        label="Join Our Club"
                        path="https://docs.google.com/forms/d/e/1FAIpQLSeoS7i4UlSE0Fz1uUTX-2gnheiXxwpn3SsX_WZKcRj8GuU1_Q/viewform?fbclid=IwAR2F-41lvN28vTdHcibGZYWenNw844gEUmQ5QOWnv2qhPZ-cNSGva6At5UA"
                        className="navButton-menu" />
                    </div>

          </div>
        </div>
      </>
    );
  }
  
  