import '../../../css/App.css';
import wsuLogo from '../../../images/wsu-logo.png';
import UoWLogo from '../../../images/UoW-logo.png';

import { ScrollTrigger, Tween } from "react-gsap";
import { useRef } from 'react';
import { Link } from 'react-router-dom';

export default function Sponsor() {
    const section = useRef(null);

    function showAnimation(){
            section.current.classList.add("fadeUp");
        }

    return(
        <>
        <ScrollTrigger trigger=".homeSecond" 
              start="-20% center" 
              end="90% center"  scrub={0.5} onEnter={() => showAnimation()}
              scrubber="1"
              
              >

              
            <Tween>
                <div ref={section} className='sponsors section'>
                    <div className="sponsorsText">
                        <h2>Our Sponsors</h2>
                        <p>We are incredibly grateful for our sponsors who have supported us in our mission to advance rocketry in Waikato, and inspire the next generation of aerospace professionals. </p>
                    </div>

                    <div className="sponsorsLogoContainer no-blur">
                        <Link to="https://wsu.org.nz/" className='sponsorsLink'>
                            <img className="sponsorLogo" alt="WSU logo" src={wsuLogo}></img>
                        </Link>
                        <Link to="https://eng.waikato.ac.nz/" className='sponsorsLink'>
                            <img className="sponsorLogo" alt="University of Waikato logo" src={UoWLogo}></img>
                        </Link>
                    </div>
                </div>
            </Tween>
        </ScrollTrigger>
        
        </>
    );

    
}