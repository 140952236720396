import '../../css/App.css';
import Hero from '../../public_components/Hero.js';
import NewsSection from './Components/NewsSection';
import JoinClubSection from '../../public_components/JoinClubSection';
import Sponsor from './Components/Sponsor';

import homeImg from '../../images/hero_pukeko.webp'
import secondImg from '../../images/earth.webp'

import { ScrollTrigger, Tween } from "react-gsap";



export default function Home() {

  function showSecondImg () {
    const bg = document.getElementById('homeSecondImg');
    bg.classList.remove('fadeOut');
    bg.classList.add('animated');
    bg.classList.add('fadeIn');
    bg.classList.add('show');
  }
  
  function hideSecondImg () {
    const bg = document.getElementById('homeSecondImg');
    bg.classList.remove('fadeIn');
    bg.classList.add('fadeOut');
  }


  return (
    <>
      <div className='App' id="scene">

        <div className='home page'>
        <ScrollTrigger trigger=".homeNews" start="-25% top" end="150% bottom" scrub={0.5}
               >
            <Tween
              from={{
                y: "0px",
                filter: "brightness(1)"}} 
              to={{
                y: "-500px",
                filter: "brightness(-0.1)"}} 
                duration={2}
                
            >
              <div className="homeBg backgroundImage">
                <img src={homeImg} alt="Rocket going up!" style={{visibility:"hidden"}}></img>
              </div>
              
            </Tween>
        </ScrollTrigger>
          
        <Hero  />
        <NewsSection />
        </div>

        <div className='homeSecond page'>
            <ScrollTrigger 
              trigger=".homeSecond" 
              start="-25% center" 
              end="90% center" 
              scrub={0.5} 
              onEnter= { () => showSecondImg() }
              onLeaveBack={
                () => hideSecondImg()
              }
              >
              <Tween
              from={{
                y: "10px"}}
              to={{
                y: "-210px"}} 

                duration={2}
              >
                <div id="homeSecondImg"className='homeSecondImg backgroundImage'>
                  <img alt="Earth from space" src={secondImg} style={{visibility:"hidden"}}></img>
                </div>
              </Tween>
            </ScrollTrigger>
            
            <Sponsor />
            <JoinClubSection />
          </div>
      </div>
      
    </>
    
  );

  
}

