import LinkButton from "../../public_components/LinkButton"
import VerticalLine from "../../public_components/VerticalLine"

export default function ContactForm() {
    return (
        <>
        <div className="section blur contactForm centered">
            <p>If you have any questions about us or you'd like to sponsor our club, please do not hesitate to contact us. </p>
            <VerticalLine />
            <p>Email: general@uwac.nz </p>
            <LinkButton 
                label="Open Email"
                className="openEmailButton"
                path="mailto:general@uwac.nz?subject=I have a question..."
            />
        </div>
        </>
    )
}