import CommitteeMember from "./CommitteeMember";

export default function Committee(){

    return(
        <>
        <div className="committee section no-blur no-border">
            <div className="container">
                <h1>2024 Committee</h1>
                <div className="committeeList">
                    <CommitteeMember 
                    name="Victor Peresson"
                    role="Joint President"/>

                    <CommitteeMember 
                    name="Logan Cook"
                    role="Joint President"/>

                    <CommitteeMember 
                    name="Jonathan Pearse"
                    role="Vice President"/>  
                    
                    <CommitteeMember 
                    name="Troy Smith"
                    role="Treasurer"/>

                    <CommitteeMember 
                    name="Zac Isaac / David Millar"
                    role="Secretary"/>

                    <CommitteeMember 
                    name="Emma Thorburn"
                    role="General Executive"/>

                    <CommitteeMember 
                    name="Rowan Zhang"
                    role="General Executive"/>

                    <CommitteeMember 
                    name="Anthony Wigley"
                    role="General Executive"/>

                </div>
            </div>
            
            
        </div>

        <div className="committee section no-blur no-border">
            <div className="container">
                <h1>2023 Committee</h1>
                <div className="committeeList">
                    <CommitteeMember 
                    name="Victor Peresson"
                    role="Joint President"/>

                    <CommitteeMember 
                    name="Logan Cook"
                    role="Joint President"/>

                    <CommitteeMember 
                    name="Jonathan Pearse"
                    role="Vice President"/>  
                    
                    <CommitteeMember 
                    name="Troy Smith"
                    role="Treasurer"/>

                    <CommitteeMember 
                    name="Zac Isaac"
                    role="Secretary"/>

                    
                </div>
            </div>
            
            
        </div>

        <div className="committee section no-blur no-border">
            <div className="container">
                <h1>2022 Committee</h1>
                <h5 id="foundingTitle">Founding</h5>
                <div className="committeeList">
                    <CommitteeMember 
                    name="Victor Peresson"
                    role="President"/>

                    <CommitteeMember 
                    name="Logan Cook"
                    role="Vice President"/>

                    <CommitteeMember 
                    name="Nikhil Rajendram"
                    role="Treasurer"/>  

                    <CommitteeMember 
                    name="Liam Pearce"
                    role="Secretary"/>

                    
                </div>
            </div>
            
            
        </div>
        </>
    )
}