
import ArticlePostsContainer from "../../public_components/ArticlePostsContainer";


export default function NewsArticleList () {
    
/*
    Add this code to the big filters gap when ready
    import Dropdown from "../../public_components/Dropdown";


    <div className="filterByContainer">
        <h3>Filter By Tag</h3>
        <Dropdown />
    </div>
*/

    return(
        <>
            <div className="newsArticleList blur section">
                <div className="container">
                    <div className="filters">
        

                    </div>
                    
                    <ArticlePostsContainer 
                    
                    />

                </div>
                
            </div>
        </>
    )
}