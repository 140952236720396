import { ScrollTrigger, Tween } from "react-gsap";
import TitleSection from "../../public_components/TitleSection"
import ContactForm from "./ContactForm"
import '../../css/Contact.css'

export default function Contact() {
    return(
        <div className="contact page">
            <ScrollTrigger 
             trigger=".page"
            start="-1% top" 
            end="150% bottom" 
            scrub={0.5} 
            >
            <Tween
              from={{
                y: "-20px"}}
              to={{
                y: "-30vh"}} 
            >
              <div className='contactBg backgroundImage'>
                
                 </div>
            </Tween>
            </ScrollTrigger>
            
            <TitleSection 
                title="Contact Us"/>

            <ContactForm />

        </div>
    )
}