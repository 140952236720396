
export default function ErrorPage() {
  
  return (
    <div className="errorContainer">
      <div id="error-page">
        <h1 className="error-header">404 - Page not found!</h1>
        <p>Whoops! Its seems your rocket has landed in the wrong place!<br/> 
        Try another page.</p>
      </div>
    </div>
    
  );
}