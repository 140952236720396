import '../css/App.css';
import HorizontalLine from './HorizontalLine';

export default function TitleSection (props) {

    const title = props.title;
    const className = props.className;
    return (
        <>
            <div className= { className + ' title section' }>
                <div className='titleContainer'>
                    <h1> {title}  </h1>
                    <HorizontalLine />
                </div>
                
            </div>
        </>
    )
}