import HorizontalLine from '../../public_components/HorizontalLine';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useNavigate } from 'react-router-dom';
export default function ArticleTitleSection (props) {

    let navigate = useNavigate();

    const title = props.title;
    const image = props.image;
    const date = props.date;

    return (
        <>
            <div className="articleTitleContainer"> 
                <div className='backButton' onClick={() => { navigate('/news')}}>
                    <ArrowBackIosNewIcon />
                    <h3 className='hover-underline-animation'>News</h3>
                </div>
                
                <div className='articleGradient'></div>
                <img className="articleImage" src={image} alt="Article pic"></img>
                <div className='articleTitle'>
                    <p className="articleDate">{date}</p>
                    <div className="articleName">
                        <h1> {title}  </h1>
                        <HorizontalLine />
                    </div>

                </div>
               
            </div>
        </>
    )
}