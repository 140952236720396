import '../css/App.css';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

export default function Button(props) {

    let navigate = useNavigate();

    const label = props.label;
    const className = props.className;
    const button = useRef(null);
    const path = props.path;


    function buttonHover(){
        button.current.classList.add("buttonHover");

        document.documentElement.style.setProperty("--hover-width", (button.current.getBoundingClientRect().width+1)+"px");
        document.documentElement.style.setProperty("--hover-height", (button.current.getBoundingClientRect().height)+"px");

    }
    
    function buttonHoverOut(){
        button.current.classList.remove("buttonHover");
    }

 
    return(
        <>
        <div className='buttonContainer'>
            <button ref={button} className={"button " + className} onMouseEnter={() => buttonHover()} onMouseLeave={() => buttonHoverOut()} onClick={() => { navigate(path)}}><p>{label}</p></button>
           
        </div>
        
        </>
    )
}