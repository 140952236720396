import '../css/App.css'
import HorizontalLine from './HorizontalLine';
import { useEffect, useRef } from 'react'

export default function EventInfo(props) {

    const day = props.day;
    const month = props.month;
    const title = props.title;
    const desc = props.desc;
    const url = props.url;

    var currentMonth = useRef(null);
    var text = useRef(null);
    var date = useRef(null);
    var divider = useRef(null);

    useEffect(() => {
        

        //Fix kerning on each Day lol

        //if the day is over 10
        if(day.length === 2){
            if(day[0] === 1){
                //kerning for days 10-19
                if(day[1] !== 1){
                    //fix kerning if day doesn't end with one
                    currentMonth.current.style.marginLeft = "4px";
                }
            }
            else if(day[0] === 2){
                //kerning for days 20-30
                if(day[1] === 1){
                    //fix kerning if day ends with 1
                    currentMonth.current.style.marginLeft = "-3px";
                }
            }
            else if(day[0] === 3){
                //kerning for the 30th and 31st
                if(day[1] === 1){
                    //fix kerning if day ends with 1
                    currentMonth.current.style.marginLeft = "-2px";
                }
            }
        }
        
        //handle 'No upcoming events'
        if(day === ""){
            date.current.style.display = "none";
            text.current.classList.add("noEvents");
        }

      }, []
      )

    return (
        <>
        <div className='eventContainer'>
            <div ref={date} className='dateBox'>
                <h1 className="eventInfoDay">{day}</h1>
                <p className="eventInfoMonth" ref={currentMonth}>{month}</p>
            </div>
            <div ref={text} className='textContainer'>
                <a className='eventTitleLink' target="_blank" rel="noopener noreferrer" href={url}>{title}</a>
                <p >{desc}</p> 
            </div>
            

        </div>
        <HorizontalLine ref={divider}
        className="eventDivider"/>
        </>
    )
}