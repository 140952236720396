import HorizontalLine from "../../public_components/HorizontalLine";

export default function CommitteeMember(props) {

    const name = props.name;
    const role = props.role;

    return (
        <>
        <div className="committeeMember no-blur">
            <h2>{name}</h2>
            <h5>{role}</h5>
            <HorizontalLine />
        </div>
        </>
    )
}