import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArticleTitleSection from "./ArticleTitleSection";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Articles from "../../public_components/Articles";
import ScrollToTop from '../../public_components/ScrollToTop';

export default function ArticlePage(props) {

    let navigate = useNavigate();
    
    let {id} = useParams();
    let Article = Articles.find((Articles)=> Articles.id === id) ;
    const {image, imgAlt, articleName, date, text, tags } = Article;

    const showTags = useCallback(() =>  {
        let i = 0;
        let tagContents = "";
        
        const tagContainer = document.getElementById(id);
        
        for(i = 0; i < tags.length; i++){
            tagContents += '<div class="tag"><p>'+tags[i].toString()+'</p></div>';
        }

        tagContainer.innerHTML = tagContents;
    }, [id, tags]);

    useEffect(() => {
        showTags(tags);

    }, [showTags, tags]);

    return(
        <>
        <div className="article page">
            
            <ArticleTitleSection 
                title = {articleName}
                image= {image}
                imgAlt={imgAlt}
                date={date}
            />
           
            <div className="articleTextContainer">
                <div className="articleText">
                    <div className="tagContainer articlePageTags" id={id}></div>
                    <p className='articleTextProper'>
                        {text}
                    </p>
                    <div className='backButton' onClick={() => { navigate('/news')}}>
                    <ArrowBackIosNewIcon />
                    <h3 className=' hover-underline-animation'>News</h3>
                </div>
                </div>
            </div>
            
        </div>
        
        </>
    )
}