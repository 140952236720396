import "../css/App.css"

export default function HorizontalLine(props) {

    var className = props.className;
    
    return(
        <>
            <div className={className + ' horizontalLine'}></div>
        </>
    )
}