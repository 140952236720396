import '../css/App.css';

import LinkButton from './LinkButton';
import VerticalLine from './VerticalLine';

import { ScrollTrigger, Tween } from "react-gsap";
import { useRef } from 'react';

export default function JoinClubSection() {

    const section = useRef(null);

    //show animation when marked scroll position is triggered 
    function showAnimation(){
        section.current.classList.add("fadeUp");
    }

    return(
        <>
        <ScrollTrigger trigger=".joinClub" start="-450px center" end="100% center" scrub={0.5}  onEnter={() => showAnimation()}>
            <Tween>
            <div ref={section} className='joinClub section centered'>
                 <h2>Be Part of Our Mission</h2>
                 <p>Get involved and join our club to connect with like-minded individuals.</p>
                 <VerticalLine />
                 <LinkButton 
                    label="Join Our Club"
                    path="https://docs.google.com/forms/d/e/1FAIpQLSeoS7i4UlSE0Fz1uUTX-2gnheiXxwpn3SsX_WZKcRj8GuU1_Q/viewform?fbclid=IwAR2F-41lvN28vTdHcibGZYWenNw844gEUmQ5QOWnv2qhPZ-cNSGva6At5UA"
                    className="joinButton" />
            </div>
            </Tween>
        </ScrollTrigger>

        </>
    );
}