import { ScrollTrigger, Tween } from "react-gsap";

import NavImage from '../images/logo/uwac_star_logo_noPadding.png';
import { Link, useLocation} from 'react-router-dom';

export default function NavbarLogo(){
    let location = useLocation();


    if(location.pathname === "/"){
        return(
            <ScrollTrigger start="200px top" end="500px bottom" scrub={0.5}
                >
                <Tween
                from={{
                    opacity: "0%"}} 
                to={{
                    opacity: "100%"}} 
                    duration={2}
                    
                >
                <Link style={{display: "flex"}} to="/">
                    <img className='navLogo' src={NavImage} alt="UWAC Logo"></img>
                </Link>
                
                </Tween>
            </ScrollTrigger>
        )
    }
    else{
        return(
            <Link style={{display: "flex"}} to="/">
                <img className='navLogo' src={NavImage} alt="UWAC Logo"></img>
            </Link>
        )
    }
    
}