import '../css/App.css';
import NavImage from '../images/logo/uwac_star_logo_noPadding.png';


export default function Hero() {
    return(
        <>
        <div className='hero title section'>
            <div className='container'>
                <span className='heroSpace'></span>
                    <div className='heroText'>
                        <img className="heroImage" alt="UWAC logo" src={ NavImage }></img>
                        <h2 className='heroSubheading'>University of Waikato Astronautics Club</h2>
                    </div>
                <span className='heroSpace'></span>
            </div>
        
        </div>
        </>
    );
}
