import '../css/App.css';

import { useRef } from 'react';

export default function LinkButton( props ) {
    
    const label = props.label;
    const path = props.path;
    const className = props.className;
    const button = useRef(null);

    function buttonHover(){
        button.current.classList.add("buttonHover");

        document.documentElement.style.setProperty("--hover-width", (button.current.getBoundingClientRect().width+1)+"px");
        document.documentElement.style.setProperty("--hover-height", (button.current.getBoundingClientRect().height)+"px");

    }
    
    function buttonHoverOut(){
        button.current.classList.remove("buttonHover"); 
    }


    return(
<>
            <a target="_blank" href={path} className={className} rel="noreferrer">
                <button ref={button} className={"button " + className} onMouseEnter={() => buttonHover()} onMouseLeave={() => buttonHoverOut()}><p>{label}</p></button>
            </a>
            
            </>
    )
}