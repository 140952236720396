import '../css/App.css';
import footerImage from '../images/logo/uwac_star_logo_noPadding.png';
import facebook from '../images/facebook-logo.svg';
//import discord from '../images/discord-logo.svg';
import LinkButton from './LinkButton';

import { Link } from 'react-router-dom';

export default function Footer() {

    return(
        <>
            <div className='footerSection'>
                <div className='footer container'>
                    <div className='footerLogoContainer'>
                        <Link className='footerLogoContainer' to="/">
                            <img src={footerImage} className='footerLogo' alt="UWAC Logo"></img>
                        </Link>
                        
                    </div>
                    <div className='footerNavContainer'>
                        <NavLink>Home</NavLink>
                        <NavLink to="/news">News</NavLink>
                        <NavLink to="/ourteam">Our Team</NavLink>
                        <NavLink to="/contact">Contact</NavLink>

                        <div className='navItem'>
                            <LinkButton 
                            label="Join Our Club"
                            path="https://docs.google.com/forms/d/e/1FAIpQLSeoS7i4UlSE0Fz1uUTX-2gnheiXxwpn3SsX_WZKcRj8GuU1_Q/viewform?fbclid=IwAR2F-41lvN28vTdHcibGZYWenNw844gEUmQ5QOWnv2qhPZ-cNSGva6At5UA"
                            className="navButton" />
                        </div>

                    </div>
                    <div className='footerSocialContainer'>
                        <img src={facebook} onClick={()=> {window.open("https://www.facebook.com/UoWAC.NZ", "_blank")}} alt="Link to UWAC Facebook"></img>
                        {/* <img className="discordLogo" src={discord} onClick={()=> {window.open("https://www.facebook.com/UoWAC.NZ", "_blank")}} alt="Link to UWAC Discord"></img> */}
                    </div>
                    
                </div>
            </div>
        </>
    );

}

function NavLink ({ to, children, props}){
    return (
      <div className="navItem">
          <Link to={to}>
            <h3>{children}</h3>
          </Link>
      </div>
    )
  }