import Navbar from "./public_components/Navbar"
import Home from "./routes/Home/Home"
import News from './routes/News/News'
import OurTeam from './routes/Our Team/OurTeam'
import Contact from './routes/Contact/Contact'
import Error from './routes/ErrorPage'
import Footer from "./public_components/Footer"
import './css/App.css'

import { Route, Routes } from "react-router-dom"
import { useEffect } from "react"
import NewsLanding from "./routes/News/NewsLanding"
import ArticlePage from "./routes/News/ArticlePage"
import ScrollToTop from "./public_components/ScrollToTop"

//Needs to be here to keep prefetched images in memory
let imageArray = {};

//Gets all images in the image folder. Used for preloading images
function importAll(r) {
	let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
	return images
}
 
export default function App () {

    useEffect(() => {

        //Disable navbar animations so they aren't playing on load
        document.body.classList.add('preload');
        //allow animations again
        setTimeout(function(){
            document.body.className="";
        },500);

        //Telemetry via Microsoft Clarity
        const telemetry = document.createElement("script");
        //Animation library
        const animatecss = document.createElement("link");

        animatecss.rel = "stylesheet";
        animatecss.href = "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css";

        telemetry.innerHTML = '(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "hcw4kaenoq");';
        document.head.appendChild(telemetry);
        document.head.appendChild(animatecss);
        
        window.addEventListener("unhandledrejection", event => {
            console.error("Unhandled Promise Rejection:", event.reason);
          });

        //Gets all image names and preload all images after the current page is done loading
        const onPageLoad = () => {
            const imagesNames = importAll(require.context('./images', false, /\.(png|jpe?g|svg|webp)$/));

            for(const [key, pic] of Object.entries(imagesNames)) {
                const img = new Image();
                img.src = pic;
                imageArray[key] = img;
            }
        };

        if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }


       

    }, []);

    return(
        <>
        <Navbar/>
        <Routes>
            <Route path="/" element={ <Home /> }/>
            <Route path="/news" element={ <News /> }>
                <Route path="" element={ <NewsLanding /> } />
                <Route path="article/:id" element={ <ArticlePage /> } />
            </Route>
            <Route path="/ourteam" element={ <OurTeam /> }/>
            <Route path="/contact" element={ <Contact /> }/>
            <Route path="/*" element={ <Error /> }/>
        </Routes>
        <ScrollToTop />
        <Footer />
        </>
    )
}