import "../../css/News.css"
import { Outlet } from "react-router-dom";

export default function News() {
    return(
        <>  
        <Outlet />
        </>
        
    )
}