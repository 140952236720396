import { ScrollTrigger, Tween } from "react-gsap";
import TitleSection from "../../public_components/TitleSection"
import Committee from "./Committee";
import "./../../css/ourTeam.css"
import JoinClubSection from "../../public_components/JoinClubSection";

export default function OurTeam() {
    return(
        <div className='ourTeam page'>
             <ScrollTrigger 
             trigger=".page"
            start="-1% top" 
            end="150% bottom" 
            scrub={0.5} 
            >
            <Tween
              from={{
                y: "-20px"}}
              to={{
                y: "-30vh"}} 
            >
              <div className='ourTeamBg backgroundImage'>
                 </div>
            </Tween>
            </ScrollTrigger>
            
            <TitleSection 
                title="Our Team"/>

            <Committee />


            <JoinClubSection />

        </div>
    )
}