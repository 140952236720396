import TitleSection from "../../public_components/TitleSection"
import NewsArticleList from "./NewsArticleList";
import '../../css/App.css';
import { ScrollTrigger, Tween } from "react-gsap";


export default function NewsLanding() {
    return(
        <>
            <div className='news page'>
            
            <ScrollTrigger 
            start="-1% top" 
            end="100% centre" 
            scrub={0.5} 
            >
            <Tween
            from={{
                y: "-20px"}}
            to={{
                y: "-30vh"}} 
            >
            <div className='newsBg backgroundImage'>
                </div>
            </Tween>
            </ScrollTrigger>

            <TitleSection 
                title="News"
                />

            <NewsArticleList />

            </div>

        </>
    )
}