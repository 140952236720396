import { useCallback, useEffect, useRef, } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ArticleCard (props) {

    let navigate = useNavigate();

    const articleName = props.articleName;
    const date = props.date;
    const tags = props.tags;
    const image = props.image;
    const imgAlt = props.imgAlt;
    const id = props.id;
    const size = props.size;
    
    const containerRef = useRef(null);
    const imgContainerRef = useRef(null);
    const textContainerRef = useRef(null);

    const showTags = useCallback(() => {
        let i = 0;
        let tagContents = "";
        
        const tagContainer = document.getElementById(id);
        
        for(i = 0; i < tags.length; i++){
            tagContents += '<div class="tag"><p>'+tags[i].toString()+'</p></div>';
        }

        tagContainer.innerHTML = tagContents;
    }, [id, tags]);

    useEffect(() => {
        showTags(tags);
    }, [showTags, tags]);

    function articleHover(){
        const imageDiv = containerRef.current.childNodes[0];
        const textDiv = containerRef.current.childNodes[1].childNodes[0];
        
        imageDiv.childNodes[0].classList.add('articleCardImageHover');

        imageDiv.classList.add('articleCardImageBorderHover');

        document.documentElement.style.setProperty("--hover-width", (textDiv.getBoundingClientRect().width+3)+"px");
        document.documentElement.style.setProperty("--hover-height", (textDiv.getBoundingClientRect().height+1)+"px");

        textDiv.classList.add("ac-descriptionContainerHover");
    }
    
    function articleHoverOut(){
        const imageDiv = containerRef.current.childNodes[0];
        const textDiv = containerRef.current.childNodes[1].childNodes[0];
        
        imageDiv.childNodes[0].classList.remove('articleCardImageHover');

        imageDiv.classList.remove('articleCardImageBorderHover');

        textDiv.classList.remove("ac-descriptionContainerHover");
    }


    return(
        <>
        <div ref={containerRef} className='articleCardContainer' onMouseEnter={() => articleHover()} onMouseLeave={() => articleHoverOut()} onClick={() => { navigate("/news/article/"+id)}}>
            <div ref={imgContainerRef} className='imageContainer'>
                <img src={image} alt={imgAlt}></img>
            </div>
            <div className='descriptionContainerContainerlol'>
                <div ref={textContainerRef} className='descriptionContainer'>
                    <div>
                        <p className='articleCardDate'>{date}</p>
                        <h2>{articleName}</h2>
                    </div>
                   
                    <div className='tagContainer' id={id}>
                    </div>
                </div>
            </div>
            
        </div>
        </>
    )

}