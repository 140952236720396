import ArticleCard from './ArticleCard';
import Articles from "./Articles";
import { useLocation } from 'react-router-dom';

export default function ArticlePostsContainer() {

    let ArticleList = Articles;
    let location = useLocation();

    console.log(window.innerWidth);

    if(location.pathname === "/"){
        ArticleList =  Articles.slice(0, 4);
    }

    return(
        <div className='postsContainer'>
            {ArticleList.map((Articles) =>{
                        
                return (
                <ArticleCard 
                    id= {Articles.id}
                    articleName= {Articles.articleName}
                    date = {Articles.date}
                    image = {Articles.image}
                    imgAlt= {Articles.imgAlt}
                    tags ={Articles.tags}
                    />
                    )  
            })}

        </div>
    )
}