import article1 from '../images/hero_pukeko.webp'
import article2 from '../images/ksp_and_agm.webp'
import article3 from '../images/kudos_event.webp'
import article4 from '../images/victor_with_rocket.webp'
import article5 from '../images/article5.webp'
import article6 from '../images/article6.webp'
import article7 from '../images/article7.webp'
import article8 from '../images/article8.webp'
import article9 from '../images/article9.webp'

import CommunityTag from "./Tags/CommunityTag"
import ConstructionTag from "./Tags/ConstructionTag"
import RocketLaunchTag from "./Tags/RocketLaunchTag"
import EventTag from "./Tags/EventTag"
import AGMTag from "./Tags/AGMTag"
import GamingTag from './Tags/GamingTag'

const Articles = [
    
    {
        id:'9',
        articleName: 'This Takahē can fly!',
        date: '28/08/2024',
        image:  article9,
        imgAlt: "Takahē in flight",
        tags: [RocketLaunchTag, ConstructionTag],
        text: [`The University of Waikato Astronautics Club continues to soar to new heights with the first launch of this year’s flagship rocket, Takahē!`,
            <br/>, <br/>,
            `On the 7th of July, Takahē had her maiden launch, achieving a club record of 9,500 feet (2.9km) and another record with a top speed of 1241km/h. This highlights the constant improvement and hard work towards furthering rocketry at Waikato University. `,
            <br/>, <br/>,
            `Takahē signifies a huge increase in complexity and pushing UWAC’s manufacturing processes forward. The fuselage is made of carbon fibre, with a fibreglass nosecone, and an aluminium nosecone tip similar to Kākāpō. This rocket also features an all-in-house designed custom airbrakes, which are set to be tested in the second launch in early November. Takahē also incorporates a club-made prototype flight computer and a payload with video recording abilities (Which you can see in action `,<a href='https://www.youtube.com/watch?v=uD9vS6qT1Gs'>here</a>,`). This is the first time a UWAC rocket has flown a dedicated payload and airbrakes. Lessons learned from Kākāpō were key to the success of Takahē.`,
            <br/>, <br/>,
            `UWAC are set to compete internationally as a part of the Australian Universities Rocket Competition (AURC) this year. She is entered in the 10,000ft category, in which rockets need to get as close as possible to this altitude.`,
            <br/>, <br/>,
            `This achievement would not have been possible without help from our main project sponsor, the School of Engineering, without whom, this flightless bird would have lived up to its namesake. Their support is crucial to the continued operation of UWAC, and we express our sincere appreciation for their contribution.`,
            <br/>, <br/>, <br/>,
            `Photo credit: Dr. Van Tiel`
            ]
    },
    {
        id:'8',
        articleName: 'Kākāpō takes it\'s first flight!',
        date: '02/04/2024',
        image:  article8,
        imgAlt: "Kākāpō in flight",
        tags: [RocketLaunchTag, ConstructionTag],
        text: [`The University of Waikato Astronautics Club (UWAC) achieved a major milestone on the March 3rd 2024 by flying our Kākāpō rocket for the first time. This flight marks a significant leap forward for our club and demonstrates our commitment to pushing rocketry forward at the University of Waikato.`,
            <br/>, <br/>,
            `Kākāpō reached an impressive height of 9,200 feet (approximately 2.8 kilometres). This altitude surpasses any flight we’ve flown in the past, which was last held by Pūkeko’s highest flight of around 3,000 feet.`,
            <br/>, <br/>,
            `The Kākāpō rocket went through the atmosphere at a maximum speed of Mach 0.92 (equivalent to 320 meters per second). Its nosecone tip was constructed out of aluminium as the heating forces on the tip would have been significant while at top speed. The rest of the rocket is constructed out of carbon fibre and fibre glass.`,
            <br/>, <br/>,
            `Not only was this a ground breaking flight for the club in terms of performance, but also was amazing leaning exercise for the Kākāpō project team. In the past, the club has used pre-made kits where most parts were manufactured before kit assembly. In contrast, Kākāpō was designed completely from the ground up by UWAC, and the majority of the manufacturing was done in house at the University of Waikato. Many lessons where leaned (Especially around working with composites), and these lessons will be used in the next club project, Takahē.`,
            <br/>, <br/>,
            `Some fun facts: During the ascent, the Kākāpō experienced a maximum gravitational force (G-loading) of 22.8 G and the flight time from launch to landing was 153 seconds.`,
            <br/>, <br/>,
            `We extend our heartfelt gratitude to the following organizations for making this project possible: School of Engineering - University of Waikato, Waikato Students’ Union, and CS³ (Computer Science Student Society). Without them, projects like this don’t happen.`,
            <br/>, <br/>,
            `Stay tuned for more updates from UWAC as we continue with our Takahē project and compete at AURC in September!`,
            <br/>, <br/>, <br/>,
            `Photo credit: Dr. Van Tiel`
            ]
    },
    {
        id:'7',
        articleName: 'Water Rocket Challenge 2023',
        date: '03/11/2023',
        image:  article7,
        imgAlt: "committee members at the summit",
        tags: [CommunityTag, EventTag],
        text: [`Did you happen to hear a cacophony of high-pressure popping sounds coming from the University field on the 4th of October? Maybe you caught a glimpse of occasional streaks of light across the sky in that direction? Or perhaps you picked up a whiff of the beautiful smell of a spring BBQ drifting in the breeze?`,
            <br/>, <br/>,
            `Recently, UWAC took to the fields alongside an army of students, water bottles, pressure (not exam-related), and snags to host the second annual UWAC Water Bottle Rocket Competition! Imagine water bottles, cardboard, duct tape, the occasional Lego figure taking flight, and a struggling BBQ burner, and you've got a front-row seat to the show.`,
            <br/>, <br/>,
            `A dozen teams of budding engineers and curious hobbyists came together to battle it out for a grand prize of $200 cash. Their goal? Make a water bottle rocket, have it shoot relatively straight, and have it shoot as far as possible.`,
            <br/>, <br/>,
            `When the splash zone finally cleared, "It's Pink Now" from Flavian Ember came out on top – an amazing achievement to take out the competition in both its first two years of running. Who said environmental students weren’t real engineers?`,
            <br/>, <br/>,
            `A huge shoutout goes to our two amazing principal event sponsors: the School of Engineering and the Waikato Students' Union. Without their unwavering support, this event wouldn't have taken flight. Not only did they provide the prize money, but they also offered the gear to make it all possible. And let's not forget those mouthwatering sausages on the day – just like a rocket needs fuel, so too do those who make and watch them!`,
            <br/>, <br/>,
            `Another year, another successful Water Bottle Rocket Competition. Tune in for more events in future, as we’ve got some mighty big plans underway for 2024 and beyond…`
            ]
    },
    {
        id:'6',
        articleName: 'NZ Aerospace Summit Trip 2023',
        date: '16/09/2023',
        image:  article6,
        imgAlt: "committee members at the summit",
        tags: [CommunityTag],
        text: [`Some of the UWAC committee members recently had the privilege of attending the New Zealand Aerospace Summit, a industry conference held in Christchurch on September 12-13, 2023. This summit is a landmark event in New Zealand’s aerospace industry, attracting over 500 attendees and making it the largest of its kind ever held in the country.`,
            <br/>, <br/>,
            'The summit featured keynote speeches from industry leaders such as Rocket Lab and Axiom Space. These speeches provided valuable insights into the current state of the aerospace industry and its future trajectory. The event also hosted panel discussions on some of the most pressing issues facing our planet, such as climate change and sustainability. These discussions highlighted the potential contributions of the aerospace sector in addressing these global challenges and making our planet greener.',
            <br/>, <br/>,
            `In addition to these informative sessions, attendees were treated to exhibitions from some of Aotearoa’s top aerospace players. These exhibitions showcased cutting-edge technologies and innovative solutions that are pushing the boundaries of what is possible in aerospace. There were Spaceplanes, Air taxis, and flown Rocket Lab hardware to be seen on the expo floor.`,
            <br/>, <br/>,
            `This summit gave the UWAC committee the opportunity to network with other attendees, visit various stands, and receive lots of free merchandise! Our participation in the summit allowed UWAC to increase its visibility within the industry and make valuable connections that will undoubtedly fuel positive change in New Zealand’s aerospace industry. These connections will also contribute to the growth and development of the club.`,
            <br/>, <br/>,
            `While in Christchurch, the UWAC committee also visited UC Aerospace, where we spent some time learning about composites and how to work with them. This educational visit provided us with practical knowledge that will be very helpful in our future projects (Like Kākāpō!). A massive thank you to UC Aerospace for showing us around the Univerity of Canterbury campus and teaching us a thing or two about how to build bleeding-edge rockets.`,
            <br/>, <br/>,
            `Looking ahead, the committee is eagerly anticipating next year’s summit/trip.`,
            <br/>, <br/>,
            `This trip was made possible thanks to the generous support of the School of Engineering at the University of Waikato. Their support underscores their commitment to fostering innovation and promoting excellence in engineering education.`,
            <br/>, <br/>, <br/>, <br/>,
            `(P.S The UWAC committee also got to see some adorable Penguins at the Antarctic Center! 🐧)`
            ]
        
    },
    {
        id:'5',
        articleName: 'The Future of UWAC',
        date: '21/08/2023',
        image:  article5,
        imgAlt: "committee member troy next to rocket",
        tags: [ ConstructionTag, CommunityTag],
        text: [`Pūkeko, the club’s second solid-fuel powered rocket, has been a fantastic intro for the club into the world of more powerful rockets. It provided a great platform for several of the club’s members to acquire their level 1 and 2 licences, which are vital to allow the purchasing and usage of larger motors. Pūkeko also provided some great practice into how to construct and use appropriate avionics, controllers, and parachute systems.`,
            <br/>, <br/>,
            'With several launches under our belt, UWAC has begun the design of a brand-new project. A custom rocket called Kākāpō. In case you haven’t noticed yet, the club has a theme of naming our rockets after native New Zealand flightless birds! ',
            <br/>, <br/>,
            `The new project has begun, which aims to build on all of the club’s previous experience from the wins and losses in Pūkeko. New systems, such as dynamically controlled air brakes, allow us to target specific altitudes dependent on the rocket’s velocity, acceleration, and height. Lightweight materials, such as carbon fibre and 3D printed titanium, will be implemented to reduce weight and increase performance without loss of strength. And to top it all off, custom designed controller(s) are being developed to control the many different systems in Kākāpō.`,
            <br/>, <br/>,
            `We at UWAC know that this project is going to be a long and challenging road, but the team is enthusiastic and ready to tread new ground!`
            ]
        
    }, 
    {
        id:'4',
        articleName: 'Pūkeko flies again!',
        date: '12/06/2023',
        image:  article4,
        imgAlt: "committee member troy next to rocket",
        tags: [ ConstructionTag, RocketLaunchTag],
        text: [`Yesterday, on Sunday 11th June, we successfully flew Pūkeko to 3123ft (952m), going four times higher than we have before! We also tested out Pūkeko in its new dual deployment configuration. Both the rocket and its new dual deployment systems functioned nominally, with both the drogue and the main parachute being deployed at exactly the correct altitude.`,
            <br/>, <br/>,
            `In the lead-up to this launch, there was a serious amount of work that was done to prepare Pūkeko to go higher than it's ever gone before. Firstly, the body needed to be extended to allow space for a second piston and the drogue parachute to be installed. The avionics bay also needed to be re-engineered and manufactured, as this new configuration would need a shock cord running down the middle of the avionics section, something that wasn't possible with our previous design.`,
            <br/>, <br/>,
            `So, we got to work. Our new avionics bay was designed and simulated in Solidworks by our Project lead for Pūkeko, Victor Peresson. This was then 3D printed using PLA and installed on the rocket the next day.`,
            <br/>, <br/>,
            `On the day, we did two static ejection tests (one for each piston), which were successful and then moved on to prepping the vehicle for flight.`,
            <br/>, <br/>, 
            `We did find some issues around arming and disarming the flight computer while on the launch rail, but these were resolved by the end of the launch window, allowing us to continue with a successful third flight of Pūkeko.`,   
            <br/>, <br/>, 
            `After a few minor repairs, Pūkeko will be back to flying in the next couple of months so watch this space!`,
            <br/>, <br/>, 
            `Massive thanks to our sponsors, the School of Engineering at the University of Waikato and the Waikato Students' Union for making our launches possible.`
            ]
    }, 
    {
        id:'3',
        articleName: 'Science Spinners – Igniting the passion for science!',
        date: '01/06/2023',
        image:  article3,
        imgAlt: "UWAC Staff launching water bottle rocket for Science Spinners",
        tags: [CommunityTag, EventTag, ],
        text: [`Are you now, or have you ever been a high school student? I would say I speak for most of us when I give that a confident “Yes!”. One thing that I would’ve loved as a student, and am in fact a tad jealous of now, is the ton of opportunities and outreach programme's for students in the modern day. Imagine having been able to play and experiment with all the cool gadgets that careers in the STEM (Science, Technology, Engineering, and Mathematics) field offer!`,
            <br/>, <br/>,
            `Science Spinners is an external school programme that does exactly that, bringing exciting and innovative experiences for students to help ignite their passion for Science, Technology, Engineering, and even Mathematics! This also includes art in the form of modern, computer-based design technologies. Science Spinners is brought to us by The Kudos Science Trust, which was created to recognize the achievements of our the Waikato’s top scientists, excite young people about science and its career opportunities, and raise the profile of science within the greater Waikato community. The University of Waikato Astronautics Club (UWAC) is proud to have been able to support this programme, and as we now launch into UWAC’s involvement with Science Spinners, you can also find out more information about Science Spinners and the Kudos Science Trust here:`,
            <br/>,
            <a href='https://www.sciencespinners.org.nz/'>https://www.sciencespinners.org.nz/</a>,
            <br/>,
            <a href='https://www.thekudos.org.nz/about-us/'>https://www.thekudos.org.nz/about-us/</a>,
            <br/>, <br/>,
            `Over three fun and education filled days on the 29th to the 31st of May, UWAC members Victor, Zac, Logan, and JP, went in to put on an exhibition of rocketry as part of the STEM festivities. In pairs, the team members blasted small-scale water rockets into the sky, potentially reaching 100 metres in flight distance. They also discussed the wonders of astronautics to the young and hopefully inspired students; the constantly evolving programme even saw some of our members take to the stage to discuss ‘Rocket Science’, which inadvertently isn’t as hard as ‘Rocket Science’ is always made out to be. Passion is the key, as we all find out eventually!`,
            <br/>, <br/>,
            `Whether it is on the field testing rockets, in the workshop (or backyard) creating them, or out and about promoting astronautics, the team at the University of Waikato Astronautics Club are always on the go. Although Science Spinners is all done and dusted for now, they hope to be back again for more educational action next time!`]
        
    },
    {
        id:'2',
        articleName: 'KSP and our first AGM!',
        date: '05/04/2023',
        image:  article2,
        imgAlt: "Rocket ascending in KSP",
        tags: [CommunityTag, GamingTag, AGMTag],
        text: [`Building rockets and sending them careening into the heavens sure is hard work. Not to mention the constant threat involved with having to literally blow stuff up to get results. Instead of months of planning, effort, risks, and blown budgets, we really wish it could all instead be done cheaply in a couple hours from a chair with only a few simple button presses… Fortunately, we have Kerbal Space Program for this! Sure, it’s not quite the same thing but at least the biggest risk is only carpal tunnel.`,
            <br/>, <br/>,
            `On Wednesday, the 5th of April, we hosted our third Kerbal Space Program event at the University of Waikato Esports Arena. 12 hopeful club members joined in on the day and, aer valiantly conquering the difficulty of space flight without any broken keyboards or strained wrists, all earned their patches for successfully completing the mission.`,
            <br/>, <br/>,
            `In addition, the club’s first ever AGM was held immediately before the event. UWAC has grown rapidly over the past year to become one of the premier STEM clubs at the University, and this progress was discussed and analyzed during the proceedings. To end the AGM, an election was held to assign a new committee who will lead the club over the next year. Elected officials were as follows:`,
            <br/>, <br/>,
            'Co-President:', <b> Logan Cook</b>, 
            <br/>, <br/>,
            'Co-President:', <b> Victor Peresson</b>,
            <br/>, <br/>,
            'Vice President:', <b> Jonathan Pearse</b>,
            <br/>, <br/>,
            'Treasurer:', <b> Troy Smith</b> ,
            <br/>, <br/>,
            'Secretary:', <b> Zac Isaac</b>,
            <br/>, <br/>, <br/>,
            'Thank you to the University of Waikato Esports Club for once more allowing us to use the venue and host another successful event. Congratulates also to the 2023-2024 elected committee!']
        
    },
    {
        id:'1',
        articleName: 'Rocketry 101',
        date: '02/03/2023',
        image:  article1,
        imgAlt: "Pūkeko ascending",
        tags: [RocketLaunchTag, ConstructionTag],
        text: [`Building rockets is one of the fundamental parts of UWAC. When starting out, some of the club’s executive team got together and decided what the best way to approach rocket building was. For being the first time that many of us had actually constructed a rocket, it seemed a bit daunting at first, with a huge amount of considerations needing to be made. Eventually, with a lot of consultation with one of Waikato Uni’s lecturers, Alista Fow, it was decided that the best way to break into the rocket building business was to construct a ¼ scale patriot missile kitset.`,
            <br/>, <br/>,
            `Once the kit finally arrived from the US, the crew got together to build the set. A large amount of epoxy, and a few coats of paint later, Pūkeko was ready for it’s first launch.`,
            <br/>, <br/>,
            `Equipped with an I class motor, Pūkeko was launched out at the NZRA launch site on 5th of March. It managed to reach a height of 258m. This launch managed to get Victor Peresson, UWAC’s club co-president, his Level 1 license, a critical step in the club’s growth in rocket building.`,
            <br/>, <br/>,
            `Following Victor’s license-getting, Pūkeko was equipped with yet another motor, this time a J class motor. Again, it was launched at the NZRA launch site on 2nd April. This time, Pūkeko didn’t quite get as high, only reaching an apogee of 172m. Despite the lack of altitude, one member, Cameron, managed to get his level 1 license.`]
    }
];

export default Articles;