import '../css/App.css'
import EventInfo from './EventInfo'
import { useEffect, useRef, useState } from 'react';

export default function UpcomingEvents() {

    const upcomingEventsContainer = useRef(null);
    
    let [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        
        var lastEvent = upcomingEventsContainer.current.lastChild;
        
        console.log(upcomingEventsContainer.current.childElementCount);

        //only remove last event divider if there is more than one event BUT remove it if it is on mobile 
        if(upcomingEventsContainer.current.childElementCount >= 2 || isMobile){
            lastEvent.style.display = "none";
        }
        

        //check if it's opened on mobile
        if(window.innerWidth <= 1080){
            setIsMobile(true);
        }
        else{
            setIsMobile(false);
        }


      }, []
      )

    return(
        <>
        <div className='upcomingEvents'>
            <h2>Upcoming Events</h2>
            <div ref={upcomingEventsContainer} className='upcomingEventsContainer'>
                
                {/* use "" for no day and month :)  */}
                
                <EventInfo
                day = ""
                month = ""
                title = ""
                url = ""
                desc="Stay tuned for any upcoming events that haven't been announced yet!"
                 /> 

                {/* <EventInfo
                day = "14"
                month = "MAY"
                title = "Craft Challenge Night 2024"
                url="https://www.facebook.com/events/456370906761796"
                desc="Build a mystery vehicle in 2 hours! - 7pm The Don"
                />

                <EventInfo
                day = "22"
                month = "MAY"
                title = "Rocket Lab Careers Talk"
                url="https://www.facebook.com/events/488916216795472"
                desc="In person talk by a Rocket Lab Engineer! - 1pm S.1.04"
                /> */}

            </div>
        </div>
        
        </>

    )
}
