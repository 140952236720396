import '../../../css/App.css';
import HorizontalLine from '../../../public_components/HorizontalLine';

import Button from '../../../public_components/Button';
import UpcomingEvents from '../../../public_components/UpcomingEvents';
import ArticlePostsContainer from '../../../public_components/ArticlePostsContainer';

export default function NewsSection() {
    return(
      <div className='homeNews section'>
      
        <div className='homeNewsMain blur container'>
          <div className='newsHeading'>
            
            <h1 className="test">News</h1>
            <HorizontalLine />
          </div>
          <div className='newsPosts'>
            <h2>Recent Posts</h2>
            
            <ArticlePostsContainer />

            <Button 
            label = "See More Posts"
            className=""
            path="/news"
            />
          </div>
        </div>
        <div className='homeNewsSide no-blur'>
          <UpcomingEvents />
        </div>
     
      </div>    
    );
};